import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const IndexMoreInfo = ({name, id, id_short, type, types, links,path, children, names, neighbours, neighboursPath, images, relations, geo, phone_prefix, ecv, iso, asl, mention}) => {
  let { district, region } = relations
  
  const imageCoa = images && images.coa ? getImage(images.coa) : null
  const hasChildren = (children && (children.num_of_cities || children.num_of_villages || children.num_of_municipalities || children.num_of_districts || children.num_of_counties || children.num_of_areas || children.num_of_regions)) ? true : null
  


return (
<div className="card border-0 shadow mb-5">
  <div className="card-header bg-gray-100 py-4 border-0">
    <div className="media align-items-center">
      <div className="media-body">
        <p className="subtitle text-sm text-primary">Viac informácií</p>
      </div>
    </div>
  </div>
  <div className="card-body">
  



  <ul className="list-unstyled mb-4">
  {
  names && names.hu ? (
    <li className="mb-2 text-gray-00 text-sm text-decoration-none"> <span className="text-blue"><i className="fas fa-info-circle mr-3"></i></span><span className="text-muted">
    Maďarsky: {names.hu}</span></li>
  ) : null
  }
  {
  names && names.de ? (
    <li className="mb-2 text-gray-00 text-sm text-decoration-none"> <span className="text-blue"><i className="fas fa-info-circle mr-3"></i></span><span className="text-muted">
    Nemecky: {names.de} </span></li>
  ) : null
  }
  </ul>



  

    <ul className="list-unstyled mb-4">
    {
      // related city
      (relations && relations.cityPath != null && relations.cityName != null) ? (
        <li className="mb-2 text-gray-00 text-sm text-decoration-none"> <span className="text-blue"><i className="fas fa-city mr-3"></i></span><span className="text-muted">
        {relations.cityText}: <Link to={relations.cityPath}>{relations.cityName}</Link></span></li>
      ) : null
    }

    {
            /* 
            to add how many city districts republic has
            */
    }
    </ul>
    




    {
      hasChildren ? (
        <>
        <p>{name} obsahuje:</p>
        <ul className="list-unstyled mb-4">
        {
    // cities
      path && path.includedCities && children && children.num_of_cities? (
        <li className="mb-2 text-gray-00 text-sm text-decoration-none"> <span className="text-blue"><i className="fas fa-info-circle mr-3"></i></span><span className="text-muted">
    <Link to={path.includedCities} >
    { //children.num_of_cities
    } 140 miest</Link></span></li>
      ) : null
    }
    <li className="mb-2 text-gray-00 text-sm text-decoration-none"> <span className="text-blue"><i className="fas fa-info-circle mr-3"></i></span><span className="text-muted">
    <Link to="/mestske-casti">39 mestských častí</Link></span></li>
    {
    // villages
      path && path.includedVillages && children && children.num_of_villages? (
        <li className="mb-2 text-gray-00 text-sm text-decoration-none"> <span className="text-blue"><i className="fas fa-info-circle mr-3"></i></span><span className="text-muted">
    <Link to={path.includedVillages}>{children.num_of_villages} dedín</Link></span></li>
      ) : null
    }
    {
    // municipalities
      path && path.includedMunicipalities && children && children.num_of_municipalities? (
        <li className="mb-2 text-gray-00 text-sm text-decoration-none"> <span className="text-blue"><i className="fas fa-info-circle mr-3"></i></span><span className="text-muted">
    Celkovo <Link to={path.includedMunicipalities}>{children.num_of_municipalities} obcí</Link></span></li>
      ) : null
    }
    {
    // districts
      path && path.includedDistricts && children && children.num_of_districts ? (
        <li className="mb-2 text-gray-00 text-sm text-decoration-none"> <span className="text-blue">
    <i className="fas fa-info-circle mr-3"></i></span><span className="text-muted">
    <Link to={path.includedDistricts}>{children.num_of_districts} okresov</Link></span></li>
      ) : null
    }
    {
    // counties
      path && path.includedCounties && children && children.num_of_counties ? (
        <li className="mb-2 text-gray-00 text-sm text-decoration-none"> <span className="text-blue">
    <i className="fas fa-info-circle mr-3"></i></span><span className="text-muted">
    <Link to={path.includedCounties}>{children.num_of_counties} krajov</Link></span></li>
      ) : null
    }    
        <li className="mb-2 text-gray-00 text-sm text-decoration-none"> <span className="text-blue">
    <i className="fas fa-info-circle mr-3"></i></span><span className="text-muted">
    <Link to={path.includedAreas}>{children.num_of_areas} oblastí</Link></span></li>
        </ul></>) : null
    }
    
        
        <p className="mt-3">Regionálne členenie</p>
        <ul className="list-unstyled mb-4">
        <li className="mb-2 text-gray-00 text-sm text-decoration-none"> <span className="text-blue">
    <i className="fas fa-info-circle mr-3"></i></span><span className="text-muted">
    <Link to={path.includedRegions}>{children.num_of_regions} regiónov cestovného ruchu</Link></span></li>
        </ul>
        
    

    

    <p>Ďalšie údaje</p>
    <ul className="list-unstyled mb-4">
    {
    ecv ? (
    <li className="mb-2 text-gray-00 text-sm text-decoration-none"> <span className="text-blue"><i className="fas fa-car mr-3"></i></span><span className="text-muted">
    ŠPZ: {ecv}</span></li>
    ) : null
    }
    {
    iso ? (
    <li className="mb-2 text-gray-00 text-sm text-decoration-none"> <span className="text-blue"><i className="fas fa-info-circle mr-3"></i></span><span className="text-muted">
    ISO: {iso}</span></li>
    ) : null
    }
    {
    phone_prefix ? (
    <li className="mb-2 text-gray-00 text-sm text-decoration-none"> <span className="text-blue"><i className="fas fa-info-circle mr-3"></i></span><span className="text-muted">
    Tel. predvolba: {phone_prefix}</span></li>
    ) : null
    }
    </ul>







      
        <p className="mt-3">NUTS členenie</p>
        <ul className="list-unstyled mb-4">
        <li className="mb-2 text-gray-00 text-sm text-decoration-none">
        <span className="text-muted">NUTS1: SK0 <Link to="/">Slovensko</Link></span></li>
        <li><Link className="text-sm" to="/nuts">Viac informácií o NUTS členení</Link></li>
        </ul>








    <p>Užitočné zdroje</p>
    <ul className="list-unstyled mb-4">
    { 
    // Wikipedia link
    links && links.wiki ? (
    <li className="mb-2">
    <a className=" text-sm text-decoration-none" href={links.wiki}><i className="fa fa-globe mr-3"></i>
    <span className="text-muted">{name} na Wikipédii</span></a></li>
    ) : null 
    }
    
    <li className="mb-2"><a className=" text-sm text-decoration-none" href={"http://www.sodbtn.sk/obce/index.php"}><i className="fa fa-globe mr-3"></i><span className="text-muted">{name} na SODBTN</span></a></li>
  
    <li className="mb-2">
    <a className=" text-sm text-decoration-none" href="https://objavujslovensko.sk"><i className="fa fa-globe mr-3"></i>
    <span className="text-muted">Objavuj Slovensko</span></a></li>
    </ul>
      





      <div className="mt-3 mb-1" style={{textAlign: "center" }}>
        {
        imageCoa ? (
            <GatsbyImage image={imageCoa} alt={"Erb " + name}/>
          ) : null
        }
      </div>
      
    
  </div>
</div>
)}
export default IndexMoreInfo

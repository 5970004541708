import React from "react"
import {
    Container,
    Row,
    Col,
  } from "react-bootstrap"
import IndexData from "./index-data"
import HeroSection from "./content-blocks/hero-section"
import IndexMoreInfo from "./content-blocks/index-more-info"
import Population from "./content-blocks/population"
import Size from "./content-blocks/size"
import Tourism from "./content-blocks/tourism"

const DetailIndex = (node) => {
    const data = IndexData(node)
    const name = data.name
    const type = data.type
    
return (
<>
<section>
    <HeroSection 
        name={name}
        type={type}
        types={data.types}
        asl={null} 
        mention={null}
        images={data.images}
        population={data.population}
        size={data.size} />
    <Container className="py-5 detail">
    <Row>
        <Col lg="8">
            <Population 
                name={data.name}
                population = {data.population}
                density = {data.density}
                nationality = {data.nationality}
                isMunicipality = {data.isMunicipality}
            />
            <Size 
                type={type}
                size={data.size}
                isMunicipality={data.isMunicipality}
            />
            <Tourism 
                name={name}
                tourism={data.tourism} 
            />
        </Col>
        <Col lg="4">
            <div className="pl-xl-4">
                <IndexMoreInfo 
                    name={name}
                    id={data.id}
                    id_short={data.id_short}
                    type={type}
                    types={data.types}
                    links={data.links}
                    path={data.path}
                    children={data.children}
                    names={data.names}
                    neighbours={null}
                    neighboursPath={null}
                    images={data.images}
                    relations={data.relations}
                    geo={null}
                    phone_prefix={data.phone_prefix}
                    ecv={data.ecv}
                    iso={null}
                    asl={null}
                    mention={null}
                />
            </div>
        </Col>
    </Row>
    </Container>
</section>
</>
)}

export default DetailIndex
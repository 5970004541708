import * as React from "react"

import niceName from "../../../components/functions/nice-name"
import siteMetaSlugs from "../../queries/site-meta-slugs"

import Path from "./content-blocks-functions/path"
import Size from "./content-blocks-functions/size"
import Relations from  "./content-blocks-functions/relations"
import Population from "./content-blocks-functions/population"
import Tourism from "./content-blocks-functions/tourism"
import Density from "./content-blocks-functions/density"
import Nationality from "./content-blocks-functions/nationality"
import Names from  "./content-blocks-functions/names"
import Children from "./content-blocks-functions/children"
import Links from  "./content-blocks-functions/links"
import Images from  "./content-blocks-functions/images"
import Types from  "./content-blocks-functions/types"

const DetailData = ({node}) => {
    // MAIN DATA OBJECT  
    let detail = {}

    // TYPES
    detail.type = node && node.field_type ? node.field_type : "uknown type"
    detail.isMunicipality = 
    (detail.type && detail.type == "city" 
    || detail.type == "village" 
    || detail.type == "cityDistrict" 
    || detail.type == "military") 
    ? true 
    : false
    detail.types = Types(detail.type)
    
    // NAMES
    detail.names = Names(node)
    detail.name = detail.names.main
    
    // PATH
    const slugs = siteMetaSlugs() // links to included nodes
    detail.path = Path(node.path.alias, slugs)

    // ID
    detail.id = node.field_id ? node.field_id : null
    // ID Short
    /*  if (type == "county") {
        id_short *= 100
    } */
    detail.id_short = node.field_id_short != null ? node.field_id_short : null

    // SIZE
   detail.size = Size(node)
   // POPULATION
   detail.population = Population(node)
   // TOURISM
   detail.tourism = Tourism(node)
   // DENSITY
   detail.density = Density(detail.size, detail.population, node)
   // NATIONALITY
   detail.nationality = Nationality(node)
   // RELATIONS
   detail.relations = Relations(node)
   // IMAGES
   detail.images = Images(node)
   // LINKS
   detail.links = Links(node)
   // CHILDREN
   detail.children = Children(node)
   
    // Phone prefix
    detail.phone_prefix = node.field_phone_prefix ? node.field_phone_prefix : null
    // ECV (SPZ)
    detail.ecv = node.field_ecv ? node.field_ecv : null
    // ISO
    detail.iso = node.field_iso ? node.field_iso : null
    
    return detail
}
export default DetailData